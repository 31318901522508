import React, { useState } from 'react'
export const Context = React.createContext()


const AuthProvider = ({ children }) => {

    const [data, setData] = useState({name: 'buenas'})
    const setUser = (user) => {
        setData((prevState) => { return { ...prevState, user } })
    }
    const context = {
        ...data,
        setUser
    }

    return (
        <Context.Provider value={context}>
            {children}
        </Context.Provider>
    )
}


export default AuthProvider
